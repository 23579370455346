<template>
  <v-card
    class="elevation-0"
    :height="this.$vuetify.breakpoint.xs ? 'auto' : 'auto'"
  >
    <v-row class="mx-3 mt-n4" no-gutters>
      <v-row>
        <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
          <v-card class="mt-10 mx-1 mr-2 elevation-1">
            <v-card-subtitle><b>Receive Notifications</b></v-card-subtitle>
            <v-card-text>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-toolbar flat class="mt-n8">
                      <v-icon color="purple" left> mdi-timer-outline </v-icon>
                      Start Time
                      <v-spacer />
                      <v-switch
                        v-model="switchMeNotifyStart"
                        dense
                        :label="`${
                          switchMeNotifyStart.toString() == 'true'
                            ? 'Enable'
                            : 'Disable'
                        }`"
                        inset
                      >
                      </v-switch>
                    </v-toolbar>
                  </v-col>
                  <v-col cols="12">
                    <v-toolbar flat class="mt-n8">
                      <v-icon color="purple" left>
                        mdi-timer-off-outline
                      </v-icon>
                      End Time
                      <v-spacer />
                      <v-switch
                        v-model="switchMeNotifyEnd"
                        dense
                        :label="`${
                          switchMeNotifyEnd.toString() == 'true'
                            ? 'Enable'
                            : 'Disable'
                        }`"
                        inset
                      >
                      </v-switch>
                    </v-toolbar>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card-text>
          </v-card>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-card
                class="elevation-1 mt-10 mx-1 mr-2"
                v-on="on"
                height="120px"
              >
                <v-row>
                  <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
                    <v-row no-gutters>
                      <v-card-title
                        style="font-size: 15px; color: #424242"
                        class="mt-n5"
                      >
                        Scan Distance Tolerance
                      </v-card-title>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="5">
                    <v-row no-gutters>
                      <v-toolbar flat dense class="mt-n7">
                        <v-card-text class="ma-0 pa-0" align="left">
                          <v-select
                            class=""
                            item-text="name"
                            item-value="value"
                            v-model="distance"
                            :items="distanceItems"
                            dense
                            outlined
                            style="max-width: 200px"
                            @change="tocallscan"
                          >
                          </v-select>
                        </v-card-text>
                      </v-toolbar>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <span class="white--text">
              <small>
                <strong>*Applicable for geo-fenced locations</strong>
              </small>
            </span>
          </v-tooltip>
        </v-col>

        <v-col cols="12" sm="5" xs="5" md="6" lg="6" xl="6">
          <v-card class="mt-13 mb-5 mx-1 elevation-1">
            <v-toolbar dense flat>
              <v-toolbar-title style="font-size: medium"
                ><b>Data Correction Reasons </b>
              </v-toolbar-title>
              <v-spacer />
              <v-switch
                inset
                dense
                v-model="datacorrectionswitch"
                :label="`${
                  datacorrectionswitch == true ? 'Enabled' : 'Disabled'
                }`"
                class="mt-1 ml-5"
              ></v-switch>
            </v-toolbar>
            <v-card-text v-if="datacorrectionswitch == true">
              <v-row>
                <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="6">
                  <v-row>
                    <v-col cols="9" sm="9" xs="9" md="9" lg="9" xl="9">
                      <v-form ref="form">
                        <v-text-field
                          label="Enter Data"
                          dense
                          outlined
                          :rules="rules_datacorrect"
                          v-model="corection_data"
                          class="ml-4 mt-2"
                        ></v-text-field>
                      </v-form>
                    </v-col>

                    <v-col cols="1" sm="1" xs="1" md="1" lg="1" xl="1">
                      <v-btn
                        small
                        color="primary"
                        class="white--text mt-3"
                        @click="add_datacorrection(corection_data)"
                      >
                        <v-icon dark>mdi-plus</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
                  <v-row
                    no-gutters
                    class="ml-6"
                    v-for="(item, idx) in dataitem_datacorrection"
                    :key="idx"
                  >
                    <v-col
                      cols="10"
                      sm="7"
                      xs="7"
                      md="7"
                      lg="7"
                      xl="7"
                      class="mt-n10"
                      ><li style="list-style-type: none" class="mt-3">
                        {{ item.name }}
                      </li>
                    </v-col>
                    <v-col cols="2" sm="2" xs="2" md="2" lg="2" xl="2">
                      <v-icon
                        small
                        :disabled="item.index < 2"
                        @click="delete_feature_data_correction(item)"
                        color="red"
                        class="mt-n13 cursor"
                        >mdi-delete</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card>
            <v-toolbar dense flat>
              <v-toolbar-title style="font-size: medium"
                ><b>Live Location Tracking </b>
              </v-toolbar-title>
              <v-spacer />
              <v-switch
                inset
                dense
                v-model="trackingLive"
                :label="`${trackingLive == true ? 'Enabled' : 'Disabled'}`"
                class="mt-1 ml-5"
              ></v-switch>
            </v-toolbar>
            <v-card-text v-if="trackingLive == true">
              <v-row>
                <v-select
                  outlined
                  dense
                  label="Frequency"
                  item-text="text"
                  item-value="value"
                  v-model="trackdistance"
                  :items="trackitems"
                  style="max-width: 200px"
                />
                <div class="ml-5"><b>Live Tracking 5 pins = 1 Credit</b></div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <v-toolbar flat class="mt-2"
      ><v-spacer />
      <v-btn
        small
        class="gradient-bg white--text pa-4"
        :loading="whatsappLoading"
        @click="call_apis()"
        ><v-icon class="mr-2">mdi-content-save</v-icon> Save Changes
      </v-btn></v-toolbar
    >

    <Overlay :overlay="overlay" />
  </v-card>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { onboard_org_settings } from "@/graphql/mutations.js";
import { GetCurrentUserDetailsnew } from "@/mixins/getCurrentUserDetails.js";
import {
  data_correction_list_or_edit,
  OrganisationQrDistanceEdit,
  UpdateWorkshiftNotification,
} from "@/graphql/mutations.js";
import Overlay from "@/components/Overlay.vue";

export default {
  mixins: [GetCurrentUserDetailsnew],
  components: {
    Overlay,
  },
  computed: {
    height() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  data: () => ({
    search: "",
    trackdistance: "15",
    datacorrectionswitch: false,
    trackitems: [
      { text: "5 min", value: "5" },
      { text: "15 min", value: "15" },
      { text: "30 min", value: "30" },
      { text: "45 min", value: "45" },
      { text: "1 hour", value: "60" },
    ],
    loading: false,
    trackingLive: false,
    switchMeNotifyStart: false,
    switchMeNotifyEnd: false,
    fixedHeader: true,
    selected: [],
    selected1: [],
    corection_data: [],
    distance: "",
    MembersList2: [],
    MembersList21: [],
    DTLoading: false,
    whatsappLoading: false,
    is_face_scan_where: false,
    is_face_all_over: false,
    feedid: "",
    addCaptureValue: [],
    DataHeaders: [],
    dataitem_datacorrection: [],
    DataItems: [],
    MemberData: [
      { text: "Member Name", value: "user_name" },
      { text: "Member ID", value: "employee_id" },
    ],
    MemberData1: [
      { text: "Member Name", value: "user_name" },
      { text: "Member ID", value: "employee_id" },
    ],
    rules_datacorrect: [
      (v) =>
        (typeof v === "string" && v.trim().length !== 0) ||
        "DataCorrection Reason cannot be empty",
      (v) =>
        /^[a-z\sA-Z]{1,30}$/.test(v) ||
        "DataCorrection Reason cannot contain special characters ",
    ],
    overlay: false,
    forms: {},
    count: 0,
    count1: 0,
    DTLoading1: false,
    scanupdated: false,
    search1: "",
    btnLoading: false,
    data_id: [],
    data_id2: [],
    comments: [],
    comments2: [],

    distanceItems: [
      { name: "50 Meters", value: 50 },
      { name: "100 Meters", value: 100 },
      { name: "200 Meters", value: 200 },
      { name: "400 Meters", value: 400 },
      { name: "500 Meters", value: 500 },
      { name: "700 Meters", value: 700 },
      { name: "900 Meters", value: 900 },
    ],
  }),
  async created() {
    this.overlay = true;
    this.count = 0;
    this.count1 = 0;

    await this.GetCurrentUserDetailsnew();
    await this.list_data_correction();
    this.trackingLive =
      this.$store.getters.get_org_details[0].is_tracking_mandatory == undefined
        ? false
        : this.$store.getters.get_org_details[0].is_tracking_mandatory;
    this.datacorrectionswitch =
      this.$store.getters.get_org_details[0].is_data_correction_enabled ==
      undefined
        ? true
        : this.$store.getters.get_org_details[0].is_data_correction_enabled;

    this.trackdistance =
      this.$store.getters.get_org_details[0].track_duration == undefined
        ? "15"
        : this.$store.getters.get_org_details[0].track_duration;
  },
  watch: {
    GetCurrentOrgObject(val) {
      this.overlay = true;
      this.distance =
        this.$store.getters.get_org_details[0].organisation_qr_distance;
      this.switchMeNotifyStart = val[0].is_start_time_notification;
      this.switchMeNotifyEnd = val[0].is_end_time_notification;
      this.overlay = false;
    },
  },
  methods: {
    tocallscan() {
      this.scanupdated = true;
    },
    async phone_numbercall() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(onboard_org_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              is_data_correction_enabled: this.datacorrectionswitch,
              is_tracking_mandatory: this.trackingLive,
              track_duration: this.trackdistance,
            },
          })
        );
        // console.log(result);

        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    add_datacorrection(corection_data) {
      if (this.$refs.form.validate()) {
        var key_object = {
          name: corection_data,
        };

        var hasMatchingCode = this.dataitem_datacorrection.some(
          (obj) => obj.name === corection_data
        );
        if (hasMatchingCode == false) {
          this.dataitem_datacorrection.push(key_object);
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Data Correction Reason already exists",
          };
        }

        this.corection_data = "";
        this.$refs.form.resetValidation();
      }
    },
    delete_feature_data_correction(item) {
      for (let i = 0; i < this.dataitem_datacorrection.length; i++) {
        if (item.name == this.dataitem_datacorrection[i].name) {
          this.dataitem_datacorrection.splice(i, 1);
        }
      }
    },
    call_apis() {
      if (this.scanupdated == true) {
        this.updateDistance();
      }

      this.work_shift_notification();
      this.get_data_correction();
      this.phone_numbercall();
      this.$refs.form.resetValidation();
    },
    async list_data_correction() {
      this.overlay = true;

      this.data_id2 = [];
      try {
        let result = await API.graphql(
          graphqlOperation(data_correction_list_or_edit, {
            input: {
              action_type: "LIST",
              data_comments: this.dataitem_datacorrection.map((val) => {
                return val.name;
              }),
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        this.overlay = false;

        this.data_id = JSON.parse(
          result.data.data_correction_list_or_edit
        ).Items[0].data_id;
        this.dataitem_datacorrection = JSON.parse(
          result.data.data_correction_list_or_edit
        ).Items[0].data_comments.map((val, i) => {
          return {
            name: val,
            index: i,
          };
        });
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.overlay = false;
      }
    },
    async get_data_correction() {
      this.overlay = true;
      this.saveloader = true;
      try {
        let result = await API.graphql(
          graphqlOperation(data_correction_list_or_edit, {
            input: {
              action_type: "EDIT",
              data_id: this.data_id,
              data_comments: this.dataitem_datacorrection.map((val) => {
                return val.name;
              }),
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        var respnse = JSON.parse(result.data.data_correction_list_or_edit);
        if (respnse.Status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Updated successfully",
          };
          this.saveloader = false;
          this.list_data_correction();
          this.overlay = false;
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: JSON.parse(result.data.data_correction_list_or_edit)
              .Message,
          };
          this.saveloader = false;
          this.overlay = false;
        }
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };

        this.overlay = false;
      }
    },
    async updateDistance() {
      this.whatsappLoading = true;
      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          organisation_qr_distance: this.distance,
        };
        await API.graphql(
          graphqlOperation(OrganisationQrDistanceEdit, {
            input: inputParams,
          })
        ).then(() => {
          this.$store.commit("snackbar/showMessage", {
            content: "Saved Changes Successfully",
            color: "green",
          });
        });
        this.whatsappLoading = false;
      } catch (err) {
        this.whatsappLoading = false;
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
      }
    },

    addData(addCaptureValue) {
      var key_object = {
        name: addCaptureValue,
      };
      this.DataItems.push(key_object);
      this.addCaptureValue = "";
    },

    async work_shift_notification() {
      this.overlay = true;
      try {
        var inputParams = {
          is_start_time_notification: this.switchMeNotifyStart,
          is_end_time_notification: this.switchMeNotifyEnd,
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
        };
        await API.graphql(
          graphqlOperation(UpdateWorkshiftNotification, {
            input: inputParams,
          })
        ).then(() => {
          this.$store.commit("snackbar/showMessage", {
            content: "Saved Changes Successfully",
            color: "green",
          });
          this.overlay = false;
          this.GetCurrentUserDetailsnew();
        });
      } catch (err) {
        this.overlay = false;
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
      }
    },
  },
};
</script>

<style></style>
